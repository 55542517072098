@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #resume,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

:root {
  --toastify-color-info: rgba(4, 131, 199, 1);
  --toastify-color-success: rgba(122, 119, 2, 1);
  --toastify-color-warning: rgba(199, 194, 14, 1);
  --toastify-color-error: rgba(122, 27, 33, 1);
}
